@import '../../config';

.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $colorLighter;
  background-image: linear-gradient(
    174.87deg,
    $colorPrimaryDark,
    $colorPrimaryLight
  );
  padding: 15px 2 * $fontSizeLarge;
  min-height: 115px;
  color: white;

  &--Nav {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
  }

  &--Nav--Items {
    a {
      display: inline-block;
    }

    a:not(.item),
    div.ui {
      margin-right: 21px;
    }

    a.active,
    div.active {
      border-bottom: 3px solid white;
    }

    a,
    a:visited {
      color: white;
      text-decoration: none;
    }

    .ui.dropdown {
      .icon {
        margin-left: 0.5em;
      }

      .menu a,
      .menu span {
        color: $colorPrimary;
        font-size: 14px;
        line-height: 15px;
        font-weight: bold;
      }
    }
  }

  &--Nav--Logo {
    display: block;
    width: 150px;
    margin-right: 20px;
    p {
      font-size: 17px;
      margin-right: 20px;
    }
  }

  &--Nav--Logout {
    cursor: pointer;
  }
}
