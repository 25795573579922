@import '../../config';

.moovy-link {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin: 5px;
  font-size: 14px;
}

a.moovy-link {
  cursor: pointer;
  font: $fontFamily;
  &:hover {
    background-color: whiteSmoke;
  }
}

a.moovy-link-delete {
  @extend .moovy-link;
  color: red;
}

.moovy-link-underline {
  border-bottom: 2px solid $colorText
}