@import '../../config';

.toolbar-filters {
  display: flex;
  flex-direction: row;
}

.toolbar-filters * {
  margin-right: 10px;
}

.add-new-voucher-template-container {
  float: right;
  margin-bottom: 10px;
}

.buttons-align-right {
  margin-top: 5px !important;
}
