@import '../../../config';

.companyGrid {
  padding: 30px 48px;
  padding-bottom: 5em;
  background-color: #fff;

  h2.companyHeader {
    margin: 0;
  }

  .menuColumn {
    border-left: 4px solid rgba(0, 152, 216, 0.5);
    padding-left: 16px;
    margin-left: 16px;
    font-size: 12px;
    color: #4a4a4a;
  }
}
