@import '../../config';

.Toolbar {
  background: #fff;
  padding: 30px 2 * $fontSizeLarge;
  position: relative;

  @media (max-width: $breakPointSmallUp) {
    padding: 16px;
  }

  #toolbar-navigation .active.section {
    font-weight: 400;
  }

  &--Content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    font-size: $fontToolbarContent;

    @media (max-width: $breakPointSmallUp) {
      flex-direction: column;
    }
  }

  &--MainContent {
    width: 100%;
  }

  &--SubMenus {
    display: flex;
    flex-direction: row;

    @media (max-width: $breakPointSmallUp) {
      flex-direction: column;
    }
  }
}

.Toolbar--Button {
  &.ui.basic.button {
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
  }

  i.icon {
    color: $colorPrimary;
  }
}
