@import '../../config';

.grant-new-voucher-container {
  float: right;
  margin-bottom: 10px;
}

.granted-voucher-table-container {
  clear: both;
}
