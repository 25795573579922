.Login {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &--RealmSelection {
    margin-top: 100px;
    padding: 45px;
    width: 500px;
    background-image: linear-gradient(149deg,#19dcff,#0098d8);

    display: flex;
    flex-direction: column;

    & > * {
      margin-bottom: 15px;
    }

    &--Title {
      font-size: 18px;
      font-weight: 700;
      font-style: italic;
      letter-spacing: .3px;
      color: #fff;
      text-transform: uppercase;
    }

    &--Form {

      input[type=text] {
        padding: 15px;
        width: 100%;
        border-radius: 100px;
        border: none;
        outline: none;
        margin-bottom: 15px;
        &:focus {
          outline: none;
        }
      }

      input[type=submit] {
        display: inline-block;
        width: 100%;
        color: #0098d8;
        background-color: #fff;
        border: 0;
        border-radius: 100px;
        cursor: pointer;
        font-size: 14px;
        line-height: 14px;
        font-weight: 700;
        padding: 18px;
        appearance: none;
      }
    }
  }
}
