.activeIconContent {
  display: table;
  height: 100%;
  min-width: 100px;
  overflow: hidden;
}

.activeIcon {
  display: table-cell;
  vertical-align: middle;
}

.toggle {
  padding-left: 5px;
  padding-right: 10px;
}
