@import './config';
@import 'npm:leaflet/dist/leaflet.css';

body {
  font-family: $fontFamily;
  background: $backgroundColor;
  color: $colorText;
  font-size: $fontSize;
  width: 100%;
  overflow-x: auto;
  min-width: fit-content;
}

a {
  color: $colorPrimary;

  &:visited {
    color: $colorPrimaryDark;
  }
}

h1 {
  font-size: $fontSizeLarge;
  font-weight: 400;
  margin: 0 0 15px 0;

  @media (min-width: $breakPointSmallUp) {
    font-size: $fontSizeExtraLarge;
    margin: 0 0 35px 0;
  }
}

h2 {
  font-size: $fontSize;
  font-weight: 700;
  margin: 0 0 24px 0;

  @media (min-width: $breakPointSmallUp) {
    font-size: $fontSizeLarge;
    margin: 0 0 24px 0;
  }
}

/* removes circle around input fields when focused */
input:focus {
  outline: none;
}

.uppercase {
  text-transform: uppercase;
}

.noMargin {
  margin: 0 !important;
}

.bold {
  font-weight: 600;
}

.Admin--Page--Content {
    padding: $fontSizeLarge 2 * $fontSizeLarge;
    background: unset;
}

.primaryColor {
  color: $colorPrimaryDark;
}

.redColor {
  color: $colorRed;
}

// Semantic overrides

.ui.header {
  font-family: $fontFamily;
}

// Table body color
table.ui.table tbody {
  color: #555555;
}

// Selectable table body to have pointer cursor
.ui.selectable.table tbody {
  cursor: pointer;
}

/* Remove borders from semantic active tabs */
.ui.bottom.attached.segment.active.tab {
  border: 0;
}

/* Icons inside buttons are inlined */
button.ui.button i.icon {
  display: inline;
}

.ui.menu > .item:not(.active) {
  color: $colorPrimaryDark;
}

.ui.breadcrumb a {
  color: $colorPrimaryDark;
}

/* Modal is able to show the loader when adding these. This is a workaround because of a bug in Semantic UI */
.ui.modal .ui.loader:before {
  border-color: rgba(0,0,0,.1);
}

/* Modal is able to show the loader when adding these. This is a workaround because of a bug in Semantic UI */
.ui.modal .ui.loader:after {
  border-color: #767676 transparent transparent;
}

div label {
  color: $colorText !important
}

.react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
}

.react-datepicker__day--in-range{
  color: #fff !important;
  background-color: #216ba5 !important;
}
