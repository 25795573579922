@import '../../config';

button.ui.button.moovy-button {
  color: $colorPrimaryDark;
  background-color: $colorWhite;
  border: 0px;
  box-shadow: 2px 2px 2px 2px $colorLighter;
  &:hover {
    background-color: whiteSmoke;
  }
}
