.toolbar-filters {
  display: flex;
  flex-direction: row;
}

.toolbar-filters * {
  margin-right: 10px;
}

.checkbox-margin {
  margin-top: 10px;
}
