.invoiceRowTableToolbar {
  display: flex;
}

.invoiceRowTableToolbar > button {
  margin-left: .75em !important;
}

.invoiceRowTableToolbarGrow {
  flex: 1 0 auto;
}

.invoiceRowTableContainer {
  max-height: 500px;
  overflow-y: auto;
  margin-bottom: 1em;
}

.invoiceRevertInputField > input {
  width: 70px;
  padding: 0 !important;
  padding-left: 4px !important;
  margin-right: 4px !important;
}
