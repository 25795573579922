@import '../../../../config';

.SubMenu {
  border-left: calc($fontSize / 4) solid rgba(0, 152, 216, 0.5);
  padding-left: $fontSize;
  margin-left: $fontSize;
  font-size: $fontSizeSmall;
  color: #4a4a4a;

  &:not(:last-child) {
    margin-right: 45px;
  }

  @media (max-width: $breakPointSmallUp) {
    margin-bottom: 20px;
    padding-left: 0;
    margin-left: 0;
  }

  span.clickable {
    cursor: pointer;
  }

  span.link {
    color: #0098d8
  }

  &.active {
    border-left-color: $colorPrimaryLight;
  }

  &--Title {
    font-weight: bold;
    margin-bottom: 3px;

    @media (max-width: $breakPointSmallUp) {
      margin-left: 16px;
    }

    a, a:visited {
      color: #4a4a4a;
      text-decoration: none;
    }
  }

  &--Item {
    line-height: 1.67;
    span {
      color: $colorLight;
    }
    a, a:visited {
      text-decoration: none;
      color: $colorPrimaryDark;
    }

    @media (max-width: $breakPointSmallUp) {
      margin-left: 16px;
    }
  }
}
