@import '../../config';

@mixin transition {
  -webkit-transition: width 0.1s ease-in-out;
  -moz-transition: width 0.1s ease-in-out;
  -o-transition: width 0.1s ease-in-out;
  transition: width 0.1s ease-in-out;
}

.Search {
  height: 42px;
  width: 42px;
  margin-right: 36px;
  position: relative;

  &--Icon {
    display: inline-block;
    width: 42px;
    height: 42px;
    background-color: white;
    border-radius: 50%;

    .icon {
      display: block;
      color: $colorPrimary;
      position: relative;
      top: 9px;
      left: 11px;
    }
  }

  &--Input {
    position: absolute;
    width: 0;

    &--Hidden {
      width: 0;
      overflow: hidden;
    }
  
    &.active {
      @include transition;
      width: 544px;
    }
  
    .input {
      width: 100%;
      height: 42px;
      outline: none;
      border: 0;

      input {
        z-index: 110;
      }

      input.prompt {
        border: 0;
        outline: none;
      }

      i {
        z-index: 111;
      }
    }

    .results {
      max-height: 65vh;
      overflow-y: auto;
    }
  }

  &--Results {
    position: absolute;
    background-color: white;
    top: 48px;
    max-height: 600px;
    overflow-y: scroll;
    z-index: 110;

    a {
      text-transform: none;
    }

    &--Item {
      display: inline-block;
    }
  }
}
