@import '../../config';

.locations__modal {
  padding: 1rem;
}

.App-Bottom {
  background: white;
}

.hideLabel label {
  visibility: hidden;
}