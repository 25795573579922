/* Colors */
$colorPrimaryDark: #0098d8;
$colorPrimaryLight: #09c8ec;
$colorPrimary: $colorPrimaryDark;
$colorDark: #7d7d7d;
$colorDarker: #6d6d6d;
$colorLight: #9b9b9b;
$colorLighter: #d8d8d8;
$colorWhite: white;
$colorBlack: black;
$colorText: #4a4a4a;
$colorRed: #ff4848;

$backgroundColor: $colorWhite;
$backgroundColorDark: darken($backgroundColor, 5%);
$backgroundColorExtraDark: darken($backgroundColorDark, 5%);
$backgroundColorLight: #f1f2f3;
$backgroundColorExtraLight: lighten($backgroundColorLight, 15%);

/* Type */
$fontFamily: 'Fira Sans', sans-serif;
$secondaryFont: 'Source Sans Pro', sans-serif;
$fontSizeExtraLarge: 32px;
$fontSizeLarge: 24px;
$fontSize: 14px;
$fontToolbarContent: 14px;
$fontSizeSmall: 12px;
$fontSizeSmaller: 10px;
$textLineHeight: 1.6em;

/* Border */
$borderThick: 4px;
$borderRadiusSmall: 3px;

/* Breakpoints */
$breakPointXSmallUp: 450px;
$breakPointSmallUp: 780px;
