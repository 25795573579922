@import '../../config';

.UserInfo {
  font-size: 14px;

  a {
    color: $colorText;
  }

  .BlockListed {
    color: rgb(208, 68, 55);
  }

  form {
    padding: 15px 0;
    font-size: 14px;

    .TextInput {
      width: 100%;
      margin-bottom: 5px;
    }

    button {
      margin-top: 20px;
    }
  }

  @media (min-width: $breakPointSmallUp) {
    display: flex;
    flex-direction: row;

    &-left {
      flex-grow: 1;
    }

    &-right {
      flex-basis: 30%;
      flex-grow: 0;
      margin-left: 20px;
    }
  }
}

.DeletedUser {
  color: red;
}
